import { userConstants } from "./types";
import { toast } from "react-toastify";
import api from "../variables/api";

export const getUsers = () => {
  const request = () => {
    return { type: userConstants.GET_USERS_REQUEST };
  };
  const success = ({ users, deletedUsers }) => {
    return { type: userConstants.GET_USERS_SUCCESS, users, deletedUsers };
  };
  const failure = (error) => {
    return { type: userConstants.GET_USERS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const users = await api.get("/users");
      const filteredUsers = users.data.data.users.filter(
        (user) => user.role !== "admin"
      );
      const activeUsers = filteredUsers.filter(
        (activeUser) => activeUser.active
      );
      const deletedUsers = filteredUsers.filter(
        (activeUser) => !activeUser.active
      );
      dispatch(success({ users: activeUsers, deletedUsers }));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const updateUser = ({ userID, payload }) => {
  const request = () => {
    return { type: userConstants.UPDATE_USERS_REQUEST };
  };
  const success = () => {
    return { type: userConstants.UPDATE_USERS_SUCCESS };
  };
  const failure = (error) => {
    return { type: userConstants.UPDATE_USERS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.patch(`/users/updateUser/${userID}`, payload);
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const deleteUsers = ({ userID, deletetype, callback, callbackfail }) => {
  const request = () => {
    return { type: userConstants.DELETE_USERS_REQUEST };
  };
  const success = ({ users, deletedUsers }) => {
    return { type: userConstants.DELETE_USERS_SUCCESS, users, deletedUsers };
  };
  const failure = (error) => {
    return { type: userConstants.DELETE_USERS_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());
    let reason = "";
    try {
      const res = await api.delete(`/users/deleteUser/${userID}`, {
        data: { deletetype: deletetype },
      });
      if (res.data.status === "fail") {
        if (
          (res.data.data.pendingeventstodownload &&
            res.data.data.pendingeventstodownload.length) ||
          (res.data.data.unsignedjobs && res.data.data.unsignedjobs.length)
        )
          callbackfail(res.data.data, "download");
        else callbackfail(res.data.data.pendingeventstoexport, "export");
      } else {
        const newActiveUsers = getState().users.data.filter(
          (user) => user._id !== userID
        );
        let newDeletedUsers = [];
        console.log(newDeletedUsers);
        if (deletetype === "delete") {
          newDeletedUsers = [...getState().users.deletedUsers]; // TODO check if Company should be deleted with their assets
        } else {
          const deletedUser = getState().users.data.filter(
            (user) => user._id === userID
          );
          newDeletedUsers = [...getState().users.deletedUsers, ...deletedUser];
        }

        dispatch(
          success({ users: newActiveUsers, deletedUsers: newDeletedUsers })
        );
        callback();
      }
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        // toast.error(`${error.response.data.message}`);
        callbackfail(error.response.data.message, "Error");
      } else {
        dispatch(failure("Something went wrong"));
        callbackfail("Something went wrong", "Error");
        // toast.error("Something went wrong");
      }
    }
  };
};

export const addUser = (user) => {
  const request = () => {
    return { type: userConstants.ADD_USER_REQUEST };
  };
  const success = (user) => {
    return { type: userConstants.ADD_USER_SUCCESS, user };
  };
  const failure = (error) => {
    return { type: userConstants.ADD_USER_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      dispatch(success({ ...user }));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const getUnapprovedUsers = () => {
  const request = () => {
    return { type: userConstants.GET_UNAPPROVED_USERS_REQUEST };
  };
  const success = ({ unapprovedUsers }) => {
    return {
      type: userConstants.GET_UNAPPROVED_USERS_SUCCESS,
      unapprovedUsers,
    };
  };
  const failure = (error) => {
    return { type: userConstants.GET_UNAPPROVED_USERS_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const unapprovedUsers = await api.get("/users/getUnapprovedUsers");
      dispatch(success({ unapprovedUsers: unapprovedUsers.data.data.users }));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const approveUser = ({ userID, callback }) => {
  const request = () => {
    return { type: userConstants.APPROVE_USER_REQUEST };
  };
  const success = ({ unapprovedUsers, users }) => {
    return { type: userConstants.APPROVE_USER_SUCCESS, unapprovedUsers, users };
  };
  const failure = (error) => {
    return { type: userConstants.APPROVE_USER_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const approvedUser = await api.patch(`/users/approveStaff/${userID}`);
      const unapprovedUsers = getState().users.unapprovedUsers.filter(
        (user) => user._id !== approvedUser.data.data.user._id
      );
      const users = [...getState().users.data, approvedUser.data.data.user];
      dispatch(success({ unapprovedUsers, users }));
      callback();
      toast.success("Staff approved successfully!");
    } catch (error) {
      callback();
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const restoreUser = ({ userID, callback }) => {
  const request = () => {
    return { type: userConstants.RESTORE_USER_REQUEST };
  };
  const success = ({ deletedUsers, users }) => {
    return { type: userConstants.RESTORE_USER_SUCCESS, deletedUsers, users };
  };
  const failure = (error) => {
    return { type: userConstants.RESTORE_USER_FAILURE, error };
  };

  return async (dispatch, getState) => {
    dispatch(request());

    try {
      const restoredUser = await api.patch(`/users/restoreUser/${userID}`);
      
      
      const deletedUsers = getState().users.deletedUsers.filter(
        (user) => user._id !== restoredUser.data.data.restoredUser._id
      );
      const users = [...getState().users.data, restoredUser.data.data.restoredUser];
      
      dispatch(success({ deletedUsers, users }));
      callback();
      toast.success("User restored successfully!");
    } catch (error) {
      callback();
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
        
      }
      console.log(error);
    }
  };
};
