import { employerConstants } from "./types";
import { toast } from "react-toastify";
import api from "../variables/api";

export const updateEmployer = ({ employerID, payload }) => {
  const request = () => {
    return { type: employerConstants.UPDATE_EMPLOYER_REQUEST };
  };
  const success = () => {
    return { type: employerConstants.UPDATE_EMPLOYER_SUCCESS };
  };
  const failure = (error) => {
    return { type: employerConstants.UPDATE_EMPLOYER_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.patch(`/employers/updateEmployer/${employerID}`, payload);
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

