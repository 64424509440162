import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CircularProgress from "@material-ui/core/CircularProgress";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";

import { disablePastDates, convertMsToHM } from "variables/utils";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import moment from "moment";
import { useParams } from "react-router-dom";
import { convertToIntHour, getJobHours } from "./index";
import api from "../../variables/api";
import { handleError } from "../../variables/handleError";

const useStyles = makeStyles(() => ({
  ...styles,
  printButton: {
    "@media print": {
      display: "none",
    },
  },
  footer: {
    "@media print": {
      display: "none",
    },
  },
  cardIcon: {
    "@media print": {
      "-webkit-print-color-adjust": "exact !important",
      "color-adjust": "exact !important",
    },
  },
}));

export const getTimeDifferent = (start, end) => {
  return getJobHours(
    convertToIntHour(moment(end).format("HH:mm")),
    convertToIntHour(moment(start).format("HH:mm"))
  );
};

function unixToYYYYMMDD(unixTimestamp) {
  // Create a new Date object from the Unix timestamp
  var date = new Date(unixTimestamp);

  // Extract the year, month, and day
  var year = date.getFullYear();
  var month = ("0" + (date.getMonth() + 1)).slice(-2); // Months are 0-indexed in JavaScript
  var day = ("0" + date.getDate()).slice(-2);

  // Combine them into a YYYYMMDD format
  var yyyymmdd = year + month + day;

  return yyyymmdd;
}

const TimeReportDetails = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const params = useParams();
  const [pageIndex, setPageIndex] = React.useState(0);
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: "create",
  });
  const [timeReportLoading, setTimeReportLoading] = React.useState(true);
  const [timeDetailsData, setDetailsData] = React.useState(false);
  const timeReportData = useSelector((state) => state.bookings);
  const [employerSignImage, setEmployerSignImage] = React.useState();
  const classes = useStyles();
  const [currJob, setCurrJob] = useState();
  const [currPosition, setCurrPosition] = useState();
  const handleClick = () => {
    const elements = [
      unixToYYYYMMDD(currPosition.jobDate),
      currPosition.booked.employee.name,
      timeDetailsData.eventID,
    ];
    const docTitle = elements.join("_");
    document.title = docTitle;
    window.print();
  };

  useEffect(() => {
    (async () => {
      try {
        let response = await api.get("/events/bookings");
        setTimeReportLoading(false);
        const id = params?.id;
        const data = response?.data?.data?.bookedivents?.find((td) => td._id === id);

        setDetailsData(data);
        response?.data?.data?.bookedivents?.map((event) => {
          event?.jobs?.map((job) => {
            if (job?._id === params?.jobId) {
              setCurrJob(job);
              job?.jobPositions?.map((position) => {
                if (position._id === params.positionId) {
                  setCurrPosition(position);
                }
              });
            }
          });
        });
      } catch (err) {
        handleError(err);
      }
    })();
  }, []);

  useEffect(() => {
    if (currPosition?.employerSign && currPosition?.employerSign !== "false") {
      if (
        currPosition?.employerSign &&
        typeof currPosition.employerSign === "string" &&
        !currPosition.employerSign.includes("https://storage.googleapis.com")
      ) {
        getImage(currPosition.employerSign, currPosition?.booked?.employee?._id);
      }
    }
    async function getImage(image, id) {
      if (image.startsWith("employersign")) {
        image = `${id}/${image}`;
        const b64i = await api.post("users/getbinaryimage", { image });
        const newimage = b64i.data.image;
        setEmployerSignImage(newimage);
      }
    }
  }, [currPosition]);

  useEffect(() => {
    if (timeReportData && timeReportData.data && timeReportData.data.length) {
      return;
    }
  }, []);

  const totalWorkedHours = convertMsToHM(
    (currPosition?.confirmJobEnd ? currPosition?.confirmJobEnd : currPosition?.jobEnd) -
      (currPosition?.confirmJobStart ? currPosition?.confirmJobStart : currPosition?.jobStart)
  );
  const totalWorkedHours1 = getTimeDifferent(
    currPosition?.confirmJobStart ? currPosition?.confirmJobStart : currPosition?.jobStart,
    currPosition?.confirmJobEnd ? currPosition?.confirmJobEnd : currPosition?.jobEnd
  );
  return (
    <GridContainer>
      <GridItem xs={12}>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon className={classes.cardIcon} color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Time Reports Details</h4>
          </CardHeader>
          <CardBody>
            {timeReportData.loading || timeReportLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <GridContainer>
                  <GridItem xs={12} sm={10}>
                    <h4>Timesheet</h4>
                    <h6>Project</h6>
                    <GridContainer>
                      <GridItem xs={4}>
                        <h6>Event Name</h6>
                        <b>{timeDetailsData?.eventName}</b>
                      </GridItem>
                      <GridItem xs={4}>
                        <h6>Event Id</h6>
                        <b>{timeDetailsData?.eventID}</b>
                      </GridItem>
                      <GridItem xs={4}>
                        <h6>Company</h6>
                        <b>{currJob?.companyName}</b>
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={4}>
                        <h6>Date</h6>
                        <b>{moment(currPosition?.jobDate).format("dddd, DD.MM.YYYY")}</b>
                      </GridItem>
                    </GridContainer>
                    <GridContainer className="mt-3">
                      <GridItem xs={4}>
                        <h6>Job Position</h6>
                        <b>{currJob?.jobName}</b>
                      </GridItem>
                      <GridItem xs={4}>
                        <h6>Employee</h6>
                        <b>{currPosition?.booked?.employee?.name}</b>
                      </GridItem>
                    </GridContainer>
                    <GridContainer className="mt-3">
                      <GridItem xs={4}>
                        <h6>Hours worked</h6>
                        <b>{totalWorkedHours}</b>
                      </GridItem>
                      <GridItem xs={2}>
                        <h6>Start</h6>
                        <b>
                          {moment(
                            currPosition?.confirmJobStart
                              ? currPosition?.confirmJobStart
                              : currPosition?.jobStart
                          ).format("HH:mm")}
                        </b>
                      </GridItem>
                      <GridItem xs={2}>
                        <h6>End</h6>
                        <b>
                          {moment(
                            currPosition?.confirmJobEnd ? currPosition?.confirmJobEnd : currPosition?.jobEnd
                          ).format("HH:mm")}
                        </b>
                      </GridItem>
                      <GridItem xs={2}>
                        <h6>Break</h6>
                        <b>{totalWorkedHours1 > 6 ? "30 min" : ""}</b>
                      </GridItem>
                    </GridContainer>
                    <hr />
                    <GridContainer className="mt-3">
                      <GridItem xs={12}>
                        <h6>Approved By</h6>
                        {employerSignImage ? (
                          currPosition?.employerSign && currPosition?.employerSign !== "false" ? (
                            <img src={"data:image/jpeg;base64," + employerSignImage} alt="sign" />
                          ) : (
                            currJob?.companyName
                          )
                        ) : (
                          "Sign not found"
                        )}
                        <p>
                          <b>{currPosition?.employerName ? currPosition?.employerName : ""}</b>
                        </p>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                  <GridItem xs={12} sm={2}>
                    <div className="superstaff-details">
                      Superstaff / CGK <br />
                      A.Baumgartnerstr.44 <br />
                      1230 Wien <br />
                      Mail: <a href="mailTo:office@cgk.co.at">office@cgk.co.at</a> <br />
                      <a href="http://www.cgk.co.at/" target="_blank" rel="noreferrer">
                        www.cgk.co.at
                      </a>
                    </div>
                  </GridItem>
                </GridContainer>
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>
      <Box display="flex" justifyContent="flex-end" width="100%"  paddingLeft="15px" paddingRight="15px">
        <Button variant="contained" color="primary" className={classes.printButton} onClick={handleClick}>
          Print
        </Button>
      </Box>
    </GridContainer>
  );
};

export default TimeReportDetails;
