import { bookingConstants } from "./types";
import axios from "axios";
import { toast } from "react-toastify";
import api from "../variables/api";

// GET Request to fetch all Events
export const getBookings = () => {
  const request = () => {
    return { type: bookingConstants.GET_BOOKING_REQUEST };
  };
  const success = (bookings) => {
    return { type: bookingConstants.GET_BOOKING_SUCCESS, bookings };
  };
  const failure = (error) => {
    return { type: bookingConstants.GET_BOOKING_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const bookingsData = await api.get("/events/bookings");
      dispatch(success(bookingsData));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong!"));
        toast.error("Something went wrong!");
      }
    }
  };
};

// PATCH Request to Update Event
// export const udpateEvent = ({ id, payload }) => {
//   const request = () => {
//     return { type: eventConstants.UPDATE_EVENT_REQUEST };
//   };
//   const success = () => {
//     return {
//       type: eventConstants.UPDATE_EVENT_SUCCESS,
//     };
//   };
//   const failure = (error) => {
//     return { type: eventConstants.UPDATE_EVENT_FAILURE, error };
//   };

//   return async (dispatch) => {
//     dispatch(request());

//     try {
//       await api.patch(`/events/updateEvent/${id}`, payload);
//       toast.success("Updated event successfully!");
//       dispatch(success());
//     } catch (error) {
//       if (
//         error &&
//         error.response &&
//         error.response.data &&
//         error.response.data.message
//       ) {
//         dispatch(failure(`${error.response.data.message}`));
//         toast.error(`${error.response.data.message}`);
//       } else {
//         dispatch(failure("Something went wrong"));
//         toast.error("Something went wrong");
//       }
//     }
//   };
// };

// DELETE Request to delete Event

// export const deleteEvent = ({ id, callback }) => {
//   const request = () => {
//     return { type: eventConstants.DELETE_EVENT_REQUEST };
//   };
//   const success = ({ events }) => {
//     return {
//       type: eventConstants.DELETE_EVENT_SUCCESS,
//       events,
//     };
//   };
//   const failure = (error) => {
//     return { type: eventConstants.DELETE_EVENT_FAILURE, error };
//   };

//   return async (dispatch, getState) => {
//     dispatch(request());

//     try {
//       await api.delete(`/events/deleteEvent/${id}`);
//       const newEvents = getState().events.data.filter(
//         (event) => event._id !== id
//       );
//       dispatch(
//         success({
//           events: newEvents,
//         })
//       );
//       if (callback) {
//         callback();
//       }
//       toast.success("Deleted event successfully!");
//     } catch (error) {
//       if (callback) {
//         callback();
//       }
//       if (
//         error &&
//         error.response &&
//         error.response.data &&
//         error.response.data.message
//       ) {
//         dispatch(failure(`${error.response.data.message}`));
//         toast.error(`${error.response.data.message}`);
//       } else {
//         dispatch(failure("Something went wrong"));
//         toast.error("Something went wrong");
//       }
//     }
//   };
// };
