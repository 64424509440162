import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// core components
import CircularProgress from "@material-ui/core/CircularProgress";
import Assignment from "@material-ui/icons/Assignment";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import ZipcodeTable from "components/ReactTable/ReactTable";
import SweetAlert from "react-bootstrap-sweetalert";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import { importzipcodes, getAllzipcodes } from "../../actions/zipcodeactions";
import api from "../../variables/api";
import { handleError } from "../../variables/handleError";

const useStyles = makeStyles({ ...styles, ...tableStyles, ...alertStyles });

const zipcodes = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = React.useState(null);
  const [uploadedFile, setUploadedFile] = React.useState(null);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: "create",
  });
  const inputRef = useRef(null);
  let [zipcodeData, setZipcodeData] = useState([]);

  const classes = useStyles();
  const columns = React.useMemo(
    () => [
      {
        Header: "PLZ",
        accessor: "id",
      },
      {
        Header: "Ort",
        accessor: "location",
      },
      {
        Header: "Bundesland",
        accessor: "state",
      },
      {
        Header: "Reisekosten",
        accessor: "traveling_expenses",
      },
      {
        Header: "EffectiveTravelCost",
        accessor: "effectiveTravelCost",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  useEffect(() => {
    (async () => {
      try {
        let response = await api.get("/zipcodes");
        setZipcodeData(response?.data?.data?.Zipcodes);
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);

  const renderTable = React.useMemo(() => {
    if (!zipcodeData || !zipcodeData.length) {
      return <h3>No Data Found!</h3>;
    }

    const filteredZipcodes = zipcodeData.map((zipcode, id) => ({
      ...zipcode,
      id: zipcode?.PLZ,
      location: zipcode?.Ort,
      state: zipcode?.Bundesland,
      traveling_expenses: zipcode?.Reisekosten,
      effectiveTravelCost: zipcode?.effectiveTravelCost,
    }));

    return (
      <ZipcodeTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        cName="zipcode-table"
        columns={columns}
        data={filteredZipcodes}
      />
    );
  }, [zipcodeData]);

  const fileHandler = (event) => {
    let fileObj = event.target.files[0];
    setUploadedFile(fileObj);
    let fileName = fileObj.type;
    const isExcel =
      fileName === "application/vnd.ms-excel" ||
      fileName ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    if (!isExcel) {
      setAlert(
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title="Invalid!"
          onConfirm={() => {
            hideAlert();
          }}
          onCancel={() => {
            hideAlert();
          }}
          confirmBtnCssClass={classes.button + " " + classes.success}
        >
          Invalid File.
        </SweetAlert>
      );
      return;
    } else {
      let formData = new FormData();
      formData.append("zipcode", fileObj);

      dispatch(
        importzipcodes({
          payload: formData,
          callback: () => {
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title="Invalid!"
              onConfirm={() => {
                hideAlert();
              }}
              onCancel={() => {
                hideAlert();
              }}
              confirmBtnCssClass={classes.button + " " + classes.success}
            >
              File Imported Successfully!
            </SweetAlert>;
            event.target.value = null;
            inputRef.current.value = null;
          },
          failCallback: () => {
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Invalid!"
              onConfirm={() => {
                hideAlert();
              }}
              onCancel={() => {
                hideAlert();
              }}
              confirmBtnCssClass={classes.button + " " + classes.success}
            >
              Error in File Upload!
            </SweetAlert>;
          },
        })
      );
    }
    event.target.value = null;
    inputRef.current.value = null;
    //just pass the fileObj as parameter
    // ExcelRenderer(fileObj, (err, resp) => {
    //   if(err){
    //     console.log(err);
    //   }
    //   else{
    //     this.setState({
    //       cols: resp.cols,
    //       rows: resp.rows
    //     });
    //   }
    // });
  };
  const hideAlert = () => {
    setAlert(null);
  };
  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Zipcodes</h4>
          </CardHeader>
          <CardBody>
            {/*<Button
              variant="contained"
              color="primary"
              onClick={(e) =>
                setClassicModal({
                  ...classicModal,
                  type: "create",
                  data: null,
                  isVisible: true,
                })
              }
            >
              Import zipcodes
            </Button>*/}
            <input
              type="file"
              className="btn btn-primary"
              color="primary"
              ref={inputRef}
              onChange={(e) => {
                fileHandler(e);
              }}
              style={{ padding: "10px" }}
            />

            {/* {zipcodeData.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : ( */}
            <p>{renderTable}</p>
            {/* )} */}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};
export default zipcodes;
