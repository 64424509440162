import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";

// @material-ui/core components
import {makeStyles} from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Flag from "@material-ui/icons/Flag";
import CircularProgress from "@material-ui/core/CircularProgress";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import EventTable from "components/ReactTable/ReactTable";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

import {handleError} from "../../variables/handleError";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import api from "../../variables/api";
import {toast} from "react-toastify";
import moment from "moment";

const useStyles = makeStyles({...styles, ...alertStyles});

const JobTeasers = () => {
    const dispatch = useDispatch();
    const [alert, setAlert] = useState(null);
    const [pageIndex, setPageIndex] = React.useState(0);
    const [eventLoading, setEventLoading] = React.useState(true);
    const [jobTeaserList, setJobTeaserList] = useState([]);
    const [mounted, setMounted] = useState(true)

    const classes = useStyles();

    const columns = React.useMemo(
        () => [
            {
                Header: "ID",
                accessor: "event.eventID",
            },
            {
                Header: "Event",
                accessor: "eventName"
            },
            {
                Header: "Company",
                accessor: "companyName",
            },
            {
                Header: "Job",
                accessor: "jobName",
            },
            {
                Header: "Place",
                accessor: "jobPlace"
            },
            {
                Header: "Date",
                accessor: "jobDate"
            },
            {
                Header: "Start",
                accessor: "jobStart"
            },
            {
                Header: "End",
                accessor: "jobEnd"
            },
            {
                Header: "",
                accessor: "actions",
            },
        ],
        []
    );
    const successDelete = async (pos) => {
        setMounted(true);
        setEventLoading(true);
        try {
            api.get(`/jobs/getJobPosition/${pos.posID}`).then(res => {
                let job = res.data.data.job[0];
                let index = job.jobPositions.findIndex((position => position._id === pos.posID))
                job.jobPositions[index].showAsTeaser = false;

                api.patch(`/jobs/updateJobByAdmin/${pos.jobID}`, {
                    jobPositions: job.jobPositions
                }).then(() => {
                    hideAlert();
                    toast.success("Job Teaser removed!");
                    fetchTeaserJobs().catch(e => {
                        console.log(e)
                    });
                });
            });
        } catch (e) {
            setEventLoading(false);
            handleError(e);
        }
        return () => {
            setMounted(false);
        };
    };
    const hideAlert = () => {
        setAlert(null);
    };
    const warningWithConfirmMessage = (pos) => {
        setAlert(
            <SweetAlert
                warning
                style={{display: "block", marginTop: "-100px"}}
                title="Remove Teaser?"
                onConfirm={() => successDelete(pos)}
                onCancel={() => hideAlert()}
                confirmBtnCssClass={classes.button + " " + classes.success}
                cancelBtnCssClass={classes.button + " " + classes.danger}
                confirmBtnText="Yes, remove it!"
                cancelBtnText="Cancel"
                showCancel
            >
                Are you sure you want to remove this job as teaser?
            </SweetAlert>
        );
    };

    const checkBox = (pos) => {
        return (
            <FormControlLabel
                required
                name="showAsTeaser"
                checked={true}
                control={<Checkbox color="primary"/>}
                onChange={() => {
                    warningWithConfirmMessage(pos)
                }}
                label=""
            />
        );
    };

    const renderTable = React.useMemo(() => {
        return (
            <EventTable
                pageIndex={pageIndex}
                setPageIndex={setPageIndex}
                columns={columns}
                data={jobTeaserList}
            />
        );
    }, [jobTeaserList]);

    async function fetchTeaserJobs() {
        try {
            api.get('jobs/getTeaserJobsList').then(response => {
                let teasers = response.data.data.jobTeasers.map(position => {
                    position['actions'] = checkBox(position);
                    return position;
                })

                setEventLoading(false);
                setJobTeaserList(teasers);
            }).catch(e => {
                setJobTeaserList([]);
                setEventLoading(false);
            });
        } catch (e) {
            Error(e);
        }
    }

    useEffect(() => {
        fetchTeaserJobs().catch(e => Error(e));
    }, []);

    return (
        <GridContainer>
            <GridItem xs={12}>
                {alert}
                <Card>
                    <CardHeader color="rose" icon>
                        <CardIcon color="rose">
                            <Flag/>
                        </CardIcon>
                        <h4 className={classes.cardIconTitle}>Job Teaser List</h4>
                    </CardHeader>
                    <CardBody>
                        {jobTeaserList.loading ? (
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: "35px 15px",
                                }}
                            >
                                <CircularProgress/>
                            </div>
                        ) : (
                            <>
                                {eventLoading && (
                                    <div className="loader absolute-loader">
                                        <CircularProgress/>
                                    </div>
                                )}
                                {jobTeaserList.length ? renderTable : (
                                    <div className="empty-list-notice">No teasers found!</div>
                                )}
                            </>
                        )}
                    </CardBody>
                </Card>
            </GridItem>
        </GridContainer>
    );
};

export default JobTeasers;
