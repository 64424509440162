import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";
// material-ui icons
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CircularProgress from "@material-ui/core/CircularProgress";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import { getUnapprovedUsers, approveUser, getUsers } from "../../actions/userAction";
import { roles, labelRoles } from "../../variables/roles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import ViewStaffDetails from "./ViewStaffDetails";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import UserTable from "components/ReactTable/ReactTable";
import UpdateModal from "./UpdateModal";
const useStyles = makeStyles({ ...tableStyles, ...alertStyles });

export default function ApproveUsers() {
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.users);
  const history = useHistory();
  const classes = useStyles();
  const [approvingUser, setApprovingUser] = useState(null);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [staffModal, setStaffModal] = React.useState(false);
  const [classicModal, setClassicModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true)
  // useEffect(() => {
  //   if (usersData && usersData.data && usersData.data.length) { setLoading(false); return; }
  //   dispatch(getUsers()).then(() => {
  //     setLoading(false)
  //   });
  // }, []);

  useEffect(() => {
    if (
      usersData &&
      usersData.unapprovedUsers &&
      usersData.unapprovedUsers.length
    ) { setLoading(false); return; }
    dispatch(getUnapprovedUsers()).then(() => {
      setLoading(false)
    });
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const handleApproveUser = (userID) => {
    setApprovingUser(userID);
    dispatch(
      approveUser({
        userID: userID,
        callback: () => {
          setApprovingUser(null);
          setStaffModal(false);
        },
      })
    );
  };

  const roundButtons = ({ userID, user }) => {
    let btnArr = [
      { type: "view", color: "primary", icon: Edit },
      { type: "edit", color: "info", icon: Edit },
      { type: "approve", color: "success", icon: Edit },
    ];
    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          disabled={approvingUser === userID}
          onClick={() => {
            if (prop.type === "view") {
              setStaffModal({
                isVisible: true,
                user,
              });
            }
            else if (prop.type === "edit") {
              setClassicModal({
                isVisible: true,
                user,
              });
            }
            else {
              handleApproveUser(userID);
            }
          }}
        >
          {prop.type === "view" ? <VisibilityIcon /> : prop.type === "edit" ? <Edit /> : "Approve User"}
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const users = usersData.unapprovedUsers;
    if (!users || !users.length) {
      return <>No Data Found!</>;
    }

    const filteredUsers = users.map((user, id) => ({
      ...user,
      id: id + 1,
      name: `${user.firstName} ${user.lastName}`,
      actions: roundButtons({
        userID: user._id,
        user,
      }),
    }));

    return (
      <UserTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        columns={columns}
        data={filteredUsers}
      />
    );
  }, [usersData]);

  return (
    <GridContainer>
      <GridItem xs={12}>
        <div className="go-back">
          <span onClick={(e) => history.push("/admin/users")}>
            <ArrowBackIosIcon />
            <span className="text-in">Go Back</span>
          </span>
        </div>
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <div className="d-flex justify-content-between align-items-center ">
              <h4 className={classes.cardIconTitle}>Approve Staff</h4>
            </div>
          </CardHeader>
          <CardBody>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>{renderTable}</>
            )}
            {staffModal.isVisible && (
              <ViewStaffDetails
                approvingUser={approvingUser}
                handleApproveUser={handleApproveUser}
                classicModal={staffModal}
                setClassicModal={setStaffModal}
                allowApprove={true}
              />
            )}
            {classicModal.isVisible && (
              <UpdateModal
                users={usersData.data}
                classicModal={classicModal}
                setClassicModal={setClassicModal}
                setLoading={setLoading}
              />
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
}
