import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SweetAlert from "react-bootstrap-sweetalert";
import Assignment from "@material-ui/icons/Assignment";
import Edit from "@material-ui/icons/Edit";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import TestimonialTable from "components/ReactTable/ReactTable";
import CreateTestimonial from "./CreateTestimonial";
import {
  getAllTestimonials,
  deleteTestimonial,
} from "../../actions/testimonialAction";

import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";

const useStyles = makeStyles({ ...styles, ...tableStyles, ...alertStyles });

const Testimonial = () => {
  const dispatch = useDispatch();
  const [alert, setAlert] = React.useState(null);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: "create",
  });
  const testimonialsData = useSelector((state) => state.testimonials);
  const classes = useStyles();
  const successDelete = async (testimonialID) => {
    dispatch(
      deleteTestimonial({
        id: testimonialID,
      })
    );

    dispatch(getAllTestimonials());

    setAlert(
      <SweetAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Deleted!"
        onConfirm={() => hideAlert()}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
      >
        Testimonial has been deleted.
      </SweetAlert>
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const warningWithConfirmMessage = (testimonialID) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(testimonialID)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText="Yes, delete it!"
        cancelBtnText="Cancel"
        showCancel
      >
        Are you sure you want to delete this testimonial ?
      </SweetAlert>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Quote",
        accessor: "quote",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ data }) => {
    let btnArr;
    btnArr = [
      { type: "edit", color: "success", icon: Edit },
      { type: "delete", color: "danger", icon: Delete },
    ];

    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          onClick={async () => {
            if (prop.type === "edit") {
              setClassicModal({
                isVisible: true,
                data,
                type: "edit",
              });
            } else if (prop.type === "delete") {
              // deleteTestimonial;
              warningWithConfirmMessage(data._id);
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const testimonials = testimonialsData.data;
    if (!testimonials || !testimonials.length) {
      return <>No Data Found!</>;
    }

    const filteredTestimonials = testimonials.map((testimonial, id) => ({
      ...testimonial,
      id: id + 1,
      actions: roundButtons({
        data: testimonial,
      }),
    }));

    return (
      <TestimonialTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        cName="testimonial-table"
        columns={columns}
        data={filteredTestimonials}
      />
    );
  }, [testimonialsData]);

  useEffect(() => {
    if (
      testimonialsData &&
      testimonialsData.data &&
      testimonialsData.data.length
    )
      return;
    dispatch(getAllTestimonials());
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Testimonials</h4>
          </CardHeader>
          <CardBody>
            <Button
              variant="contained"
              color="primary"
              onClick={(e) =>
                setClassicModal({
                  ...classicModal,
                  type: "create",
                  data: null,
                  isVisible: true,
                })
              }
            >
              Add Testimonial
            </Button>
            {testimonialsData.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>{renderTable}</>
            )}
          </CardBody>
        </Card>
        {classicModal.isVisible && (
          <CreateTestimonial
            classicModal={classicModal}
            setClassicModal={setClassicModal}
          />
        )}
      </GridItem>
    </GridContainer>
  );
};

export default Testimonial;
