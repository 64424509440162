import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import CircularProgress from "@material-ui/core/CircularProgress";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Assignment from "@material-ui/icons/Assignment";
import { getBookings } from "actions/bookingAction";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import EventTable from "components/ReactTable/ReactTable";
import { convertToDateFromMS } from "../../variables/time";
import { disablePastDates, convertMsToHM } from "variables/utils";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import api from "variables/api";

import moment from "moment";
import { getTimeDifferent } from "./TimeReportDetails";

const useStyles = makeStyles(styles);
export const convertToIntHour = (time) => {
  if (!time) {
    return 0;
  }
  const t = time.replace(":15", ".25").replace(":30", ".5").replace(":45", ".75");
  return parseFloat(t);
};
export const getJobHours = (endTime, startTime) => {
  if (startTime > endTime) {
    return endTime + 24 - startTime;
  } else {
    return endTime - startTime;
  }
};
const TimeReports = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [pageIndex, setPageIndex] = React.useState(0);
  const [classicModal, setClassicModal] = React.useState({
    isVisible: false,
    type: "create",
  });
  const [timeReportLoading, setTimeReportLoading] = React.useState(false);
  const timeReportData = useSelector((state) => state.bookings);
  const [newReports, setNewReports] = React.useState([]);

  const classes = useStyles();

  const columns = React.useMemo(
    () => [
      {
        Header: "Date",
        accessor: "jobDate",
        sortType: (a, b, columnId) => {
          var a1 = moment(a?.values?.jobDate, "DD-MM-YYYY").format("x");
          var b1 = moment(b?.values?.jobDate, "DD-MM-YYYY").format("x");
          if (a1 < b1) return 1;
          else if (a1 > b1) return -1;
          else return 0;
        },
      },
      {
        Header: "Event ID",
        accessor: "eventID",
      },
      {
        Header: "Event Name",
        accessor: "eventName",
      },
      {
        Header: "Job Position",
        accessor: "jobPosition",
      },
      {
        Header: "Employee",
        accessor: "EmpName",
      },
      {
        Header: "Hours Booked",
        accessor: "bookedHour",
        // sortType: (a, b, columnId) => {
        //   var a1 = moment(a?.values?.bookedHour, 'DD-MM-YYYY').format('x');
        //   var b1 = moment(b?.values?.bookedHour, 'DD-MM-YYYY').format('x');
        //   if (a1 < b1)
        //     return 1;
        //   else if (a1 > b1)
        //     return -1;
        //   else
        //     return 0;
        // }
      },
      {
        Header: "Start",
        accessor: "jobStartTime",
      },
      {
        Header: "End",
        accessor: "jobEndTime",
      },
      {
        Header: "Break",
        accessor: "breakTime",
      },
      {
        Header: "Aproved By",
        accessor: "employerSign",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  React.useEffect(() => {
    const timeReports = timeReportData?.data?.data;
    if (!timeReports || !timeReports?.data?.bookedivents.length) {
      setNewReports([]);
    } else {
      convertimages();
    }
    async function convertimages() {
      setNewReports([]);
      const newreports1 = await Promise.all(
        timeReports?.data?.bookedivents.map(async (timeReport) => {
          timeReport?.jobs?.map(async (job) => {
            job?.jobPositions.map(async (jobPosition) => {
              if (
                jobPosition?.employerSign !== "" &&
                jobPosition?.employerSign !== "false" &&
                jobPosition?.employerSign !== false
              ) {
                if (
                  jobPosition?.employerSign &&
                  typeof jobPosition.employerSign === "string" &&
                  !jobPosition.employerSign.includes("https://storage.googleapis.com") &&
                  jobPosition.employerSign.startsWith("employersign") &&
                  jobPosition?.booked?.employee?._id
                ) {
                  const image = `${jobPosition.booked.employee._id}/${jobPosition.employerSign}`;
                  const b64i = await api.post("users/getbinaryimage", { image });
                  jobPosition.employerSign = b64i.data.image;
                }
              }
              return jobPosition;
            });
            return job;
          });
          return timeReport;
        })
      );
      setNewReports([...newreports1]);
    }
  }, [timeReportData]);

  const roundButtons = ({ data, job, position }) => {
    let btnArr;
    btnArr = [{ type: "info", color: "info", icon: ArrowForwardIcon }];
    const url = `/admin/timereports/${data?._id}/${job?._id}/${position?._id}`;

    return btnArr.map((prop, key) => {
      return (
        <div
          key={key}
          onClick={(e) => {
            if (e.button === 0) {
              e.preventDefault();
              history.push(url);
            }
          }}
        >
          <a
            href={url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ display: "inline-block", width: "100%", height: "100%" }}
          >
            <Button
              round
              color={prop.color}
              className={`${classes.actionButton} ${classes.actionButtonRound}`}
              key={key}
            >
              <prop.icon className={classes.icon} />
            </Button>
          </a>
        </div>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    // const timeReports = timeReportData?.data?.data;
    if (!newReports || !newReports.length) {
      return <>No Data Found!</>;
    }
    let showjobPosition = "",
      showEmpName = "",
      showBookedHour = "",
      showJobStartTime = "",
      showJobEndTime = "",
      showBreakTime = "",
      tempJobStartTime = "",
      tempJobEndTime = "";
    const filteredTimeReports = [];
    newReports.map((timeReport) => {
      timeReport?.jobs?.map((job) => {
        job?.jobPositions.map((jobPosition) => {
          showEmpName = jobPosition?.booked?.employee?.name;
          showjobPosition = job?.jobName;
          // showBookedHour = moment(jobPosition?.jobEnd).set({ second: 0, millisecond: 0 }).diff(moment(jobPosition?.jobStart).set({ second: 0, millisecond: 0 }), 'hour');
          showBookedHour = getJobHours(
            convertToIntHour(moment(jobPosition?.jobEnd).format("HH:mm")),
            convertToIntHour(moment(jobPosition?.jobStart).format("HH:mm"))
          );
          showJobStartTime = jobPosition?.confirmJobStart
            ? jobPosition?.confirmJobStart
            : jobPosition?.jobStart;
          showJobEndTime = jobPosition?.confirmJobEnd ? jobPosition?.confirmJobEnd : jobPosition?.jobEnd;

          tempJobStartTime = moment(showJobStartTime);
          tempJobEndTime = moment(showJobEndTime);

          showBreakTime = moment.duration(moment(showJobEndTime).diff(moment(showJobStartTime)));
          const totalWorkedHours = getTimeDifferent(showJobStartTime, showJobEndTime);

          filteredTimeReports.push({
            ...timeReport,
            jobDate: moment(jobPosition?.jobDate).format("DD.MM.YYYY"),
            jobPosition: showjobPosition,
            EmpName: showEmpName,
            // bookedHour: Number(totalWorkedHours).toFixed(2),
            bookedHour: convertMsToHM(
              (jobPosition?.confirmJobEnd ? jobPosition?.confirmJobEnd : jobPosition?.jobEnd) -
                (jobPosition?.confirmJobStart ? jobPosition?.confirmJobStart : jobPosition?.jobStart)
            ),
            employerSign:
              jobPosition?.employerSign !== "" &&
              jobPosition?.employerSign !== "false" &&
              jobPosition?.employerSign !== false &&
              !jobPosition?.employerSign?.includes("https://storage.googleapis.com") &&
              !jobPosition?.employerSign?.startsWith("employersign") ? (
                <img src={"data:image/jpeg;base64," + jobPosition?.employerSign} alt="" />
              ) : (
                job?.companyName
              ),
            companyName: job?.companyName,
            jobStartTime:
              String(tempJobStartTime.hours()).padStart(2, "0") +
              ":" +
              String(tempJobStartTime.minutes()).padStart(2, "0"),
            jobEndTime:
              String(tempJobEndTime.hours()).padStart(2, "0") +
              ":" +
              String(tempJobEndTime.minutes()).padStart(2, "0"),
            breakTime: totalWorkedHours > 6 ? "30 min" : "",
            actions: roundButtons({
              data: timeReport,
              job: job,
              position: jobPosition,
            }),
          });
          return jobPosition;
        });
      });
    });

    return (
      <EventTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        columns={columns}
        data={filteredTimeReports}
      />
    );
  }, [timeReportData, newReports]);

  useEffect(() => {
    setNewReports([]);
    if (timeReportData && timeReportData.data && timeReportData.data.length) return;
    dispatch(getBookings());
  }, []);

  return (
    <GridContainer>
      <GridItem xs={12}>
        {alert}
        <Card>
          <CardHeader color="rose" icon>
            <CardIcon color="rose">
              <Assignment />
            </CardIcon>
            <h4 className={classes.cardIconTitle}>Time Reports</h4>
          </CardHeader>
          <CardBody>
            {timeReportData.loading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "35px 15px",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                {timeReportLoading && (
                  <div className="loader absolute-loader">
                    <CircularProgress />
                  </div>
                )}
                {renderTable}
              </>
            )}
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default TimeReports;
