import Bookings from "views/Bookings";
import CompanyUser from "views/CompanyUser";
import Event from "views/Event";
import EventStatus from "views/EventStatus";
import UpdateEvent from "views/Event/UpdateEvent";
import EventStatusDetail from "views/EventStatus/EventStatusDetail";

import Order from "views/Orders";
import CreateOrder from "views/Orders/CreateOrder";
import UpdateOrder from "views/Orders/UpdateOrder";
import Settings from "views/Settings";
import Testimonial from "views/Testimonial";
import TimeReports from "views/TimeReports";
import User from "views/User";
import ApproveEmployees from "views/User/ApproveEmployees";
import ArchivedUsers from "views/User/ArchivedUsers";
import ArchivedCompanyUsers from "views/CompanyUser/ArchivedCompanyUsers";
import zipcodes from "views/ZipCodes";
import outstandings from "views/OutStandings";
import OutstandingDetails from "views/OutStandings/OutstandingDetails";
import Contract from "views/Contract";
import ForgetPassword from "views/Pages/ForgetPasswordPage.js";
import LoginPage from "views/Pages/LoginPage.js";
import RegisterPage from "views/Pages/RegisterPage.js";
import ResetPasswordPage from "views/Pages/ResetPasswordPage.js";
import Profile from "views/Profile/Settings";
import TimeReportDetails from "views/TimeReports/TimeReportDetails";

// @material-ui/icons
import AssignmentIcon from "@material-ui/icons/Assignment";
import EventIcon from "@material-ui/icons/Event";
import Flag from "@material-ui/icons/Flag";
import FormatQuoteIcon from "@material-ui/icons/FormatQuote";
import GroupIcon from "@material-ui/icons/Group";
import Groups from "@material-ui/icons/Group";
import Image from "@material-ui/icons/Image";
import PaymentIcon from "@material-ui/icons/Payment";
import PersonIcon from "@material-ui/icons/Person";
import ZipcodesIcon from "@material-ui/icons/LocalPostOffice";
import { default as SettingsIcon } from "@material-ui/icons/Settings";
import JobTeasers from "./views/JobTeasers";

var dashRoutes = [
  {
    path: "/profile/settings",
    name: "Settings",
    component: Profile,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/users",
    name: "Users",
    icon: PersonIcon,
    component: User,
    layout: "/admin",
  },
  {
    path: "/companies",
    name: "Companies",
    icon: GroupIcon,
    component: CompanyUser,
    layout: "/admin",
  },
  {
    path: "/companies/archived-users",
    name: "Companies",
    icon: GroupIcon,
    component: ArchivedCompanyUsers,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/users/archived-users",
    name: "Users",
    icon: PersonIcon,
    component: ArchivedUsers,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/users/approve-users",
    name: "Users",
    icon: PersonIcon,
    component: ApproveEmployees,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/eventstatus",
    name: "Event Status",
    icon: EventIcon,
    component: EventStatus,
    layout: "/admin",
  },
  {
    path: "/events",
    name: "Events",
    icon: EventIcon,
    component: Event,
    layout: "/admin",
  },
  {
    path: "/jobteaser",
    name: "Job Teaser",
    icon: Flag,
    component: JobTeasers,
    layout: "/admin",
  },
  {
    path: "/events/:id",
    name: "Event",
    icon: EventIcon,
    component: UpdateEvent,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/eventstatus/:id",
    name: "Event Status Detail",
    icon: EventIcon,
    component: EventStatusDetail,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/bookings",
    name: "Bookings",
    icon: GroupIcon,
    component: Bookings,
    layout: "/admin",
  },
  {
    path: "/timereports",
    name: "Time Reports",
    icon: GroupIcon,
    component: TimeReports,
    layout: "/admin",
  },
  {
    path: "/timereports/:id/:jobId/:positionId",
    name: "Time Reports Details",
    icon: EventIcon,
    component: TimeReportDetails,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/orders",
    name: "Order",
    icon: PaymentIcon,
    component: Order,
    layout: "/admin",
  },
  {
    path: "/orders/create-order",
    name: "Order",
    icon: PaymentIcon,
    component: CreateOrder,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/orders/:id",
    name: "Order",
    icon: PaymentIcon,
    component: UpdateOrder,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/testimonials",
    name: "Testimonial",
    icon: FormatQuoteIcon,
    component: Testimonial,
    layout: "/admin",
  },
  {
    path: "/contract",
    name: "Contract",
    icon: AssignmentIcon,
    component: Contract,
    layout: "/admin",
  },

  {
    path: "/zipcodes",
    name: "Zipcode",
    icon: ZipcodesIcon,
    component: zipcodes,
    layout: "/admin",
  },
  {
    path: "/outstanding_amounts/:id",
    name: "Outstanding Amount Details",
    icon: PaymentIcon,
    component: OutstandingDetails,
    layout: "/admin",
    hidden: true,
  },
  {
    path: "/outstanding_amounts",
    name: "Outstanding Amounts",
    icon: PaymentIcon,
    component: outstandings,
    layout: "/admin",
  },
  {
    path: "/settings",
    name: "Settings",
    icon: SettingsIcon,
    component: Settings,
    layout: "/admin",
  },
  {
    collapse: true,
    name: "Pages",
    rtlName: "صفحات",
    icon: Image,
    state: "pageCollapse",
    hidden: true,
    views: [
      {
        path: "/login",
        name: "Login Page",
        rtlName: "هعذاتسجيل الدخول",
        mini: "L",
        rtlMini: "هعذا",
        component: LoginPage,
        layout: "/auth",
      },
      {
        path: "/register",
        name: "Register Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: RegisterPage,
        layout: "/auth",
      },
      {
        path: "/reset-password/:token",
        name: "Reset Page",
        rtlName: "تسجيل",
        mini: "R",
        rtlMini: "صع",
        component: ResetPasswordPage,
        layout: "/auth",
      },
      {
        path: "/forget-password",
        name: "Forget Password Page",
        rtlName: "تسجيل",
        mini: "F",
        rtlMini: "صع",
        component: ForgetPassword,
        layout: "/auth",
      },
    ],
  },
];
export default dashRoutes;
