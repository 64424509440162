import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import CustomInput from "components/CustomInput/CustomInput.js";
import ImageUpload from "components/CustomUpload/ImageUpload.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { roles } from "variables/roles";
import { countries } from "variables/user.constants";
import * as Yup from "yup";
import api from "../../variables/api";
import CircularProgress from "@material-ui/core/CircularProgress";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { getImageData } from "variables/utils";
import {updateEmployerUser} from "../../actions/syncAction";

const useStyles = makeStyles({ ...styles, ...extendedFormsStyle });

const UpdateEmployer = ({ user, classicModal, setClassicModal, setLoading }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { employer } = user;
  const [imgLoading, setImgLoading] = useState(true);
  const [alert, setAlert] = React.useState(null);
  useEffect(() => {
    async function getImages() {
      if (user && user.photo && typeof user.photo === "string" && !user.photo.includes("https://storage.googleapis.com") && (user.photo.startsWith(
                      'employee-',
                    ) ||
                      user.photo.startsWith('user-')||
          user.photo.startsWith('employer-'))) {
        const image = user.photo;
        const imagedata = await api.post("users/getImageUrl", { image })
        // user.photo = imagedata.data.image;
        formik.setFieldValue('photo', imagedata.data.image)
        setImgLoading(false)
      }
      else{
        setImgLoading(false)
      }

    }
    getImages();
  }, [user])
  const paymentMethodsChk = [
    // { label: 'Visa', value: 'visa' },
    // { label: 'Master Card', value: 'masterCard' },
    { label: 'Invoice', value: 'onInvoice' },
  ];
  const formik = useFormik({
    initialValues: {
      active: user.active,
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phone: user.phone,
      photo: user && user.photo &&(user.photo.startsWith(
                      'employee-',
                    ) ||
                      user.photo.startsWith('user-')) ? user.photo : "",
      country: employer && employer.country ? employer.country : "",
      city: employer && employer.city ? employer.city : "",
      postalCode: employer && employer.postalCode ? employer.postalCode : "",
      addressLineOne:
        employer && employer.addressLineOne ? employer.addressLineOne : "",
      addressLineTwo:
        employer && employer.addressLineTwo ? employer.addressLineTwo : "",
      contactPerson:
        employer && employer.contactPerson ? employer.contactPerson : "",
      paymentMethods: employer?.paymentMethods ? employer?.paymentMethods : [...paymentMethodsChk.map((i) => i.value)],
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is Required"),
      lastName: Yup.string().required("Last Name is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      phone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("A phone number is required")
        .test(
          "maxDigits",
          "Phone number must have atleast 9 digits",
          (number) => String(number).length >= 9
        ),
      country: Yup.string().required('Country is required'),
      city: Yup.string().required("City is Required"),
      addressLineOne: Yup.string().required("Address is Required"),
      postalCode: Yup.string()
      .required("Postal code is Required")
      .matches(/^[0-9]/, 'Postal Code can not be negative')
      .min(4, "Postal Code must be 4-5 digit")
      .max(5, "Postal Code must be 4-5 digit"),
      contactPerson: Yup.string()
        .required("Please enter contact person name")
        .matches(/^([^0-9]*)$/, "Please enter valid name"),
      paymentMethods: Yup.array().min(1, "Please select atleast one payment method")
    }),
    onSubmit: async (values) => {

      setLoading(true)

      if (user.role === roles.employer || user.role === roles.superuser) {
        // console.log("typeof photo", typeof values.photo)
        // console.log("image data", values.photo)
        let newValues = {};
        let fileName;
        if (String(values.photo).startsWith('http://') || String(values.photo).startsWith('https://')) {
          fileName =  values.photo.split('/').pop();
        } else {
          fileName = values.photo;
        }

        if ((values.photo && values.photo.length && values.photo[0].type === "Buffer") ) {
          newValues = {
            active: values.active,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            city: values.city,
            contactPerson: values.contactPerson,
            country: values.country,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            paymentMethods: values.paymentMethods,
            phone: values.phone,
            postalCode: values.postalCode
          }
        }
        else {
          newValues = {
            active: values.active,
            addressLineOne: values.addressLineOne,
            addressLineTwo: values.addressLineTwo,
            city: values.city,
            contactPerson: values.contactPerson,
            country: values.country,
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
            paymentMethods: values.paymentMethods,
            phone: values.phone,
            photo: fileName,
            postalCode: values.postalCode
          }
        }
        setClassicModal({ ...classicModal, isVisible: false });
        dispatch(
          updateEmployerUser({
            userID: user._id,
            employerID: user.employer._id,
            payload: {
              ...newValues,
            },
            callback: () => {
              setLoading(false);
            }
          })
        );
      }

    },
  }, [user]);

  const renderPaymentMethodCheckbox = (formikForm, key, item) => {
    return <label key={key} className='text-dark'>
      <input
        type="checkbox"
        className='pmntChk'
        checked={formik.values.paymentMethods.find((i) => i === item.value)}
        value={item.value}
        name="paymentMethods[]"
        onChange={(e) => {
          if (e.target.checked) {
            formik.setFieldValue('paymentMethods', [...formik.values.paymentMethods, item.value]);
          }
          else {
            formik.setFieldValue('paymentMethods', [...formik.values.paymentMethods.filter((f) => f !== item.value)]);
          }
        }}
        onBlur={formik?.handleBlur}
      />
      {item.label}
    </label>
  }

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form form-first-registration"
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="First Name"
            id="firstName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "firstName",
              value: formik.values.firstName,
               onChange:(e) => {
                if (e.target.value === '' || e.target.value?.length <= 30) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.firstName,
              errors: formik.errors.firstName,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Last Name"
            id="lastName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "lastName",
              value: formik.values.lastName,
               onChange:(e) => {
                if (e.target.value === '' || e.target.value?.length <= 50) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.lastName,
              errors: formik.errors.lastName,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Email address"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "email",
              name: "email",
              value: formik.values.email,
               onChange:(e) => {
                if (e.target.value === '' || e.target.value?.length <= 30) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.email,
              errors: formik.errors.email,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Phone Number"
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "phone",
              value: formik.values.phone,
               onChange:(e) => {
                if (e.target.value === '' || e.target.value?.length <= 20) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.phone,
              errors: formik.errors.phone,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className="company-logo-in">
            <label>Update Logo</label>
             {imgLoading ? <CircularProgress/> :
            <ImageUpload
              file={formik.values.photo}
              setFile={(file) => formik.setFieldValue("photo", file)}
              avatar={formik.values.photo}
              addButtonProps={{
                color: "rose",
                round: true,
              }}
              changeButtonProps={{
                color: "rose",
                round: true,
              }}
              removeButtonProps={{
                color: "danger",
                round: true,
              }}
              inputProps={{
                name: "photo",
              }}
            />}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Country
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.country}
              onChange={(e) => {
                formik.setFieldValue("country", e.target.value);
              }}
              inputProps={{
                name: "country",
                id: "country",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Country
              </MenuItem>
              {countries.map((country, cid) => (
                <MenuItem
                  key={country + cid}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={country.code}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.country && formik.errors.country && (
              <div className="error-message">{formik.errors.country}</div>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="City"
            id="city"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "city",
              value: formik.values.city,
              onChange: formik.handleChange,
              touched: formik.touched.city,
              errors: formik.errors.city,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Street Line One"
            id="addressLineOne"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "addressLineOne",
              value: formik.values.addressLineOne,
              onChange: formik.handleChange,
              touched: formik.touched.addressLineOne,
              errors: formik.errors.addressLineOne,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Street Line Two"
            id="addressLineTwo"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "addressLineTwo",
              value: formik.values.addressLineTwo,
              onChange: formik.handleChange,
              touched: formik.touched.addressLineTwo,
              errors: formik.errors.addressLineTwo,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Postal Code"
            id="postalCode"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "postalCode",
              value: formik.values.postalCode,
              onChange: formik.handleChange,
              touched: formik.touched.postalCode,
              errors: formik.errors.postalCode,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Contact Person"
            id="contactPerson"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "contactPerson",
              value: formik.values.contactPerson,
              onChange: formik.handleChange,
              touched: formik.touched.contactPerson,
              errors: formik.errors.contactPerson,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className='paymentMethodsWrap'>
            <label className="custom-chk">Allow Payment Method</label>
            <div className='mt-4'>
              {paymentMethodsChk?.map((item, key) => renderPaymentMethodCheckbox(formik, key, item))}
            </div>
            {formik?.touched?.paymentMethods && formik?.errors?.paymentMethods && (
              <p className='' style={{ color: 'red', fontSize: '0.75rem' }}>{formik?.errors?.paymentMethods}</p>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
            <Button
              onClick={() =>
                setClassicModal({ ...classicModal, isVisible: false })
              }
              variant="outlined"
            >
              Cancel
            </Button>
            <Button type="submit" Button variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

UpdateEmployer.propTypes = {
  user: PropTypes.any,
  classicModal: PropTypes.any,
  setClassicModal: PropTypes.any,
  setLoading: PropTypes.any
};

export default UpdateEmployer;
