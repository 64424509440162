import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SweetAlert from "react-bootstrap-sweetalert";

// material-ui icons
import { Delete, DeleteForever, Edit, Assignment } from "@material-ui/icons";
import VisibilityIcon from "@material-ui/icons/Visibility";


// core components
import CircularProgress from "@material-ui/core/CircularProgress";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Table from "components/Table/Table.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardIcon from "components/Card/CardIcon.js";
import CardHeader from "components/Card/CardHeader.js";
import UpdateModal from "./UpdateModal";
import CreateUser from "./CreateUser";
import { roles, labelRoles } from "../../variables/roles";
import {
  getUsers,
  getUnapprovedUsers,
  deleteUsers,
} from "../../actions/userAction";

import ViewStaffDetails from "./ViewStaffDetails";
import tableStyles from "assets/jss/material-dashboard-pro-react/views/extendedTablesStyle.js";
import alertStyles from "assets/jss/material-dashboard-pro-react/views/sweetAlertStyle.js";
import UserTable from "components/ReactTable/ReactTable";

import Badge from "@material-ui/core/Badge";
import { RepeatRounded } from "@material-ui/icons";

const useStyles = makeStyles({ ...tableStyles, ...alertStyles });

export default function Users() {
  const StyledBadge = withStyles((theme) => ({
    badge: {
      position: "relative",
      right: "auto",
      top: "auto",
      transform: "none !important",
      background: "#ffffff",
      color: "#9c27b0",
      marginLeft: "5px",
    },
  }))(Badge);
  const dispatch = useDispatch();
  const usersData = useSelector((state) => state.users);

  const [pageIndex, setPageIndex] = React.useState(0);
  const [alert, setAlert] = React.useState(null);
  const [classicModal, setClassicModal] = React.useState(false);
  const [createUserModal, setCreateUserModal] = React.useState(false);
  const [staffModal, setStaffModal] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const classes = useStyles();

  useEffect(() => {
    if (usersData && usersData.data && usersData.data.length) {
      setLoading(false);
    } else {
      dispatch(getUsers()).then(() => {
        setLoading(false);
      });
    }
  }, []);

  const successDelete = async (userID, deletetype) => {
    hideAlert();
    setLoading(true);
    dispatch(
      deleteUsers({
        userID,
        deletetype,
        callback: () => {
          setLoading(false);
          setAlert(
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title={deletetype === "delete" ? "Deleted!" : "Archived!"}
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnCssClass={classes.button + " " + classes.success}
            >
              <strong>
                {deletetype === "delete"
                  ? "User has been deleted."
                  : "User has been archived successfully."}
              </strong>
            </SweetAlert>
          );
          if (deletetype === 'delete') {
            return;
          }
          let deletedUserIndex = usersData.data.findIndex(u => u._id === userID);
          usersData.data[deletedUserIndex].ArchivedAt = Date.now();
          usersData.data[deletedUserIndex].active = false;
          usersData.deletedUsers.push(usersData.data[deletedUserIndex]);
        },
        callbackfail: (data, errortype) => {
          setLoading(false);
          setAlert(
            <SweetAlert
              danger
              style={{ display: "block", marginTop: "-100px" }}
              title="Error!"
              onConfirm={() => hideAlert()}
              onCancel={() => hideAlert()}
              confirmBtnCssClass={classes.button + " " + classes.danger}
            >
              <strong>
                {errortype === "export"
                  ? "Please Export The Folowing Events first to delete user."
                  : errortype === "download"
                  ? data?.unsignedjobs
                    ? "Unsigned Contracts"
                    : "Please download the following contracts before the user can be deleted."
                  : data}
              </strong>{" "}
              <br />
              <br />
              {errortype === "export"
                ? data.toString()
                : errortype === "download"
                ? data?.unsignedjobs
                  ? data.unsignedjobs.toString()
                  : data.pendingeventstodownload.toString()
                : data.toString()}
            </SweetAlert>
          );
        },
      })
    );
  };

  const hideAlert = () => {
    setAlert(null);
  };

  const warningWithConfirmMessage = (userID, deletetype) => {
    setAlert(
      <SweetAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Are you sure?"
        onConfirm={() => successDelete(userID, deletetype)}
        onCancel={() => hideAlert()}
        confirmBtnCssClass={classes.button + " " + classes.success}
        cancelBtnCssClass={classes.button + " " + classes.danger}
        confirmBtnText={
          deletetype === "delete" ? "Yes, delete it!" : "Yes, archive it!"
        }
        cancelBtnText="Cancel"
        showCancel
      >
        {deletetype === "delete"
          ? "Are you sure you want to delete this user ? All data of the user will deleted."
          : "Are you sure you want to archive this user ?"}
      </SweetAlert>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Phone",
        accessor: "phone",
      },
      {
        Header: "Actions",
        accessor: "actions",
      },
    ],
    []
  );

  const roundButtons = ({ userID, role, active, user }) => {
    let btnArr;
    if (active) {
      btnArr = [
        { type: "view", color: "primary", icon: VisibilityIcon },
        { type: "edit", color: "success", icon: Edit },
        { type: "archive", color: "warning", icon: Delete },
        { type: "delete", color: "danger", icon: DeleteForever },
      ];
    } else {
      btnArr = [{ type: "edit", color: "success", icon: Edit }];
    }
    return btnArr.map((prop, key) => {
      return (
        <Button
          round
          color={prop.color}
          className={classes.actionButton + " " + classes.actionButtonRound}
          key={key}
          onClick={() => {
            if (prop.type === "edit") {
              setClassicModal({
                isVisible: true,
                userID,
              });
            } else if (prop.type === "view") {
              setStaffModal({
                isVisible: true,
                user,
              });
            } else if (prop.type === "delete") {
              warningWithConfirmMessage(userID, "delete");
            } else if (prop.type === "archive") {
              warningWithConfirmMessage(userID, "archive");
            }
          }}
        >
          <prop.icon className={classes.icon} />
        </Button>
      );
    });
  };

  const renderTable = React.useMemo(() => {
    const users = usersData.data;
    if (!users || !users.length) {
      return <>No Data Found!</>;
    }

    const filteredUsers = users
      .filter((user) => user.role !== roles.employer)
      .map((user, id) => ({
        ...user,
        id: id + 1,
        name: `${user.firstName} ${user.lastName}`,
        // role:
        //   user.role === roles.employer
        //     ? labelRoles.employer
        //     : labelRoles.employee,
        actions: roundButtons({
          userID: user._id,
          role: user.role,
          active: user.active,
          user: user,
        }),
      }));

    return (
      <UserTable
        pageIndex={pageIndex}
        setPageIndex={setPageIndex}
        columns={columns}
        data={filteredUsers}
      />
    );
  }, [usersData]);

  return (
    <>
      <GridContainer>
        <GridItem xs={12}>
          {alert}
          <Card>
            <CardHeader color="rose" icon>
              <CardIcon color="rose">
                <Assignment />
              </CardIcon>
              <div className="d-flex justify-content-between align-items-center ">
                <h4 className={classes.cardIconTitle}>Users</h4>
                <div className="d-flex users-link">
                  <Link
                    to="/admin/users/approve-users"
                    className="btn btn-info"
                  >
                    <h5 className="btn btn-info">
                      Approve Users{" "}
                      {usersData.unapprovedUsers.length > 0 ? (
                        <StyledBadge
                          badgeContent={usersData.unapprovedUsers.length}
                          color="primary"
                        ></StyledBadge>
                      ) : (
                        ""
                      )}
                    </h5>
                  </Link>
                  <Link
                    to="/admin/users/archived-users"
                    className="u-text-underline"
                  >
                    <h5>List of Archived Users</h5>
                  </Link>
                </div>
              </div>
            </CardHeader>
            <CardBody>
              <div className="btn-wrapper">
                <Button
                  variant="contained"
                  color="primary"
                  onClick={(e) => {
                    setCreateUserModal(true);
                  }}
                >
                  Add User
                </Button>
              </div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    padding: "35px 15px",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>{renderTable}</>
              )}
            </CardBody>
          </Card>
          {staffModal.isVisible && (
            <ViewStaffDetails
              approvingUser={usersData.data}
              classicModal={staffModal}
              setClassicModal={setStaffModal}
            />
          )}
          {classicModal.isVisible && (
            <UpdateModal
              users={usersData.data}
              classicModal={classicModal}
              setClassicModal={setClassicModal}
              setLoading={setLoading}
            />
          )}
          {createUserModal && (
            <CreateUser
              classicModal={createUserModal}
              setClassicModal={setCreateUserModal}
            />
          )}
        </GridItem>
      </GridContainer>
    </>
  );
}
