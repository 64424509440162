import { userConstants } from "../actions/types";

const initialState = {
  loading: false,
  data: [],
  deletedUsers: [],
  unapprovedUsers: [],
  error: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case userConstants.GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        deletedUsers: [],
        error: "",
      };
    case userConstants.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.users,
        deletedUsers: action.deletedUsers,
        error: "",
      };
    case userConstants.GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        data: [],
        deletedUsers: [],
        error: action.error,
      };
    case userConstants.DELETE_USERS_REQUEST:
      return {
        ...state,
        // data: [],
        error: "",
      };
    case userConstants.DELETE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        // data: action.users,
        data: action.users,
        deletedUsers: action.deletedUsers,
        error: "",
      };
    case userConstants.DELETE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.UPDATE_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        data: [],
        error: "",
      };
    case userConstants.UPDATE_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
      };
    case userConstants.UPDATE_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.ADD_USER_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.ADD_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...state.data, action.user],
        error: "",
      };
    case userConstants.ADD_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.GET_UNAPPROVED_USERS_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case userConstants.GET_UNAPPROVED_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        unapprovedUsers: action.unapprovedUsers,
        error: "",
      };
    case userConstants.GET_UNAPPROVED_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.APPROVE_USER_REQUEST:
      return {
        ...state,
        error: "",
      };
    case userConstants.APPROVE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.users,
        unapprovedUsers: action.unapprovedUsers,
        error: "",
      };
    case userConstants.APPROVE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case userConstants.RESTORE_USER_REQUEST:
      return {
        ...state,
        error: "",
      };
    case userConstants.RESTORE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.users,
        deletedUsers: action.deletedUsers,
        error: "",
      };
    case userConstants.RESTORE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default userReducer;
