import React from "react";
import PropTypes from "prop-types";
import Button from "components/CustomButtons/Button.js";
import defaultImage from "assets/img/image_placeholder.jpg";
import {toast} from "react-toastify";

function getFullImageUrl(image) {
  if (!image || image === 'null' || typeof image !== 'string') {
    return defaultImage;
  }
  if (!image.startsWith('public/')) {
    return image;
  }
  return `${process.env.REACT_APP_GCS_BUCKET_PUBLIC}/${image}`;
}

export default function ImageUpload(props) {
  const {file, setFile} = props;
  const [imagePreviewUrl, setImagePreviewUrl] = React.useState(getFullImageUrl(props.avatar));

  React.useEffect(() => {
    setImagePreviewUrl(getFullImageUrl(props.avatar));
  }, [props.avatar]);

  let fileInput = React.createRef();
  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    if (!file) {
      return;
    }

    if (file.type === "image/jpeg" || file.type === "image/jpg" || file.type === "image/png") {
      if (file.size > 1000000) {
        toast.error("Image size should not be greater than 1MB.")
        return;
      }
    } else {
      toast.error("Image type should be jpg, jpeg or png.")
      return;
    }

    reader.onloadend = () => {
      setFile(file);
      setImagePreviewUrl(reader.result);
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  };
  const handleClick = () => {
    fileInput.current.click();
  };
  const handleRemove = () => {
    setFile(null);
    setImagePreviewUrl(defaultImage);
    fileInput.current.value = null;
  };
  let {
    avatar,
    addButtonProps,
    changeButtonProps,
    removeButtonProps,
    inputProps,
  } = props;
  return (
    <div className="fileinput text-center">
      <input
        type="file"
        onChange={handleImageChange}
        ref={fileInput}
        {...inputProps}
      />
      <div className={"thumbnail"}>
        <img src={imagePreviewUrl} alt="..."/>
      </div>
      <div>
        {(file === null || avatar === 'null') ? (
          <Button {...addButtonProps} onClick={() => handleClick()}>
            {avatar ? "Add Photo" : "Select image"}
          </Button>
        ) : (
          <span
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Button
              {...changeButtonProps}
              onClick={() => handleClick()}
              style={{
                marginRight: "8px",
              }}
            >

              {(avatar && avatar !== 'null') ? "Change" : "Add"}
            </Button>
            {(avatar && avatar !== 'null') ? (
              <Button {...removeButtonProps} onClick={() => handleRemove()}>
                <i className="fas fa-times"/> Remove
              </Button>
            ) : (
              ""
            )}
          </span>
        )}
      </div>
    </div>
  );
}

ImageUpload.propTypes = {
  file: PropTypes.any,
  setFile: PropTypes.any,
  avatar: PropTypes.any,
  inputProps: PropTypes.any,
  addButtonProps: PropTypes.object,
  changeButtonProps: PropTypes.object,
  removeButtonProps: PropTypes.object,
};
