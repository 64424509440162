import axios from "axios";
import jwt_decode from 'jwt-decode';

const API = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  responseType: "json",
});

function handleInvalidToken() {
  localStorage.clear();
  delete API.defaults.headers.common.Authorization;
  setTimeout(() => {
    window.location.replace("/");
  }, 1000);
}

API.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("token");
    const user = localStorage.getItem("user");

    if (token && user) {
      try {
        const decodedToken = jwt_decode(token);
        const parsedUser = JSON.parse(user);
        const currentTime = Date.now() / 1000;
        if (decodedToken.id !== parsedUser._id) {
          handleInvalidToken();
        }
        if (decodedToken.exp < currentTime) {
          handleInvalidToken();
        } else {
          config.headers.Authorization = `Bearer ${token}`;
        }
      } catch (error) {
        handleInvalidToken();
      }
    } else {
      delete API.defaults.headers.common.Authorization;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default API;
