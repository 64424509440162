import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Check from "@material-ui/icons/Check";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import Checkbox from "@material-ui/core/Checkbox";
import Datetime from "react-datetime";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import CloseIcon from "@material-ui/icons/Close";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CustomInput from "components/CustomInput/CustomInput.js";
import CheckboxWrapper from "components/CheckboxWrapper/CheckboxWrapper";
import { updateEmployeeUser } from "../../actions/syncAction";
import { roles } from "variables/roles";
import { disableFutureDt } from "variables/utils";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  hairColorOptions,
  sizeOptions,
  shoeSizeOptions,
  countries,
  languages,
  jobRegions,
} from "variables/user.constants";
import { getUnsignedURL } from "variables/utils";
import { toast } from "react-toastify";
import styles from "assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import extendedFormsStyle from "assets/jss/material-dashboard-pro-react/views/extendedFormsStyle.js";
import JobSelect from "react-select";
import api from "../../variables/api";
import { handleError } from "../../variables/handleError";

const useStyles = makeStyles({ ...styles, ...extendedFormsStyle });

const UpdateEmployee = ({
  user,
  classicModal,
  setClassicModal,
  setLoading,
}) => {
  const [imgLoading, setImgLoading] = React.useState(true);
  const [userImg, setUserImg] = React.useState([]);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { employee } = user;
  const [jobPositionsList, setJobPositionsList] = React.useState([]);
  const [preferedJobRegions, setPreferedJobRegions] = React.useState([]);

  const [userPhoto, setUserPhoto] = React.useState();

  React.useEffect(() => {
    async function getImages() {
      if (
        user &&
        user?.employee &&
        user.employee.images &&
        user.employee.images.length
      ) {
        let img;
        user.employee.images.map(
          async (image) => (
            (img = await api.post("users/getImageUrl", { image })),
            setUserImg((prevState) => [...prevState, img]),
            setImgLoading(false)
          )
        );
      }
    }
    getImages();
  }, [user]);
  React.useEffect(() => {
    (async () => {
      try {
        let response = await api.get("/global-settings");
        let resJobs = response.data.settings[0].staffTypes;
        setPreferedJobRegions();
        const filteredJobPositions = resJobs.map((job) => ({
          label: job.name,
          value: job.name,
        }));
        setJobPositionsList(filteredJobPositions);
      } catch (error) {
        handleError(error);
      }
    })();
  }, []);
  const style = {
    control: (base) => ({
      ...base,
      border: 0,
      // This line disable the blue border
      boxShadow: "none",
    }),
  };
  const formik = useFormik({
    initialValues: {
      active: user.active ? true : false,
      firstName: user.firstName ? user.firstName : "",
      lastName: user.lastName ? user.lastName : "",
      email: user.email ? user.email : "",
      phone: user.phone ? user.phone : null,
      images: employee && employee.images ? employee.images : [],
      gender: employee && employee.gender ? employee.gender : "",
      motherTongue:
        employee && employee.motherTongue ? employee.motherTongue : "",
      nationality: employee && employee.nationality ? employee.nationality : "",
      hairColor: employee && employee.hairColor ? employee.hairColor : "",
      height:
        employee && employee.height ? employee.height.replace("cm", "") : "",
      weight:
        employee && employee.weight ? employee.weight.replace("kg", "") : "",
      foreignLanguages:
        employee && employee.foreignLanguages ? employee.foreignLanguages : [],
      size: employee && employee.size ? employee.size : "",
      shoeSize: employee && employee.shoeSize ? employee.shoeSize : "",
      piercings: employee && employee.piercings ? employee.piercings : false,
      country: employee && employee.country ? employee.country : "",
      postalCode: employee && employee.postalCode ? employee.postalCode : "",
      addressLineOne:
        employee && employee.addressLineOne ? employee.addressLineOne : "",
      addressLineTwo:
        employee && employee.addressLineTwo ? employee.addressLineTwo : "",
      city: employee && employee.city ? employee.city : "",
      dateOfBirth: employee && employee.dateOfBirth ? employee.dateOfBirth : "",
      visibleTattoos:
        employee && employee.visibleTattoos ? employee.visibleTattoos : false,
      driversLicense:
        employee && employee.driversLicense ? employee.driversLicense : false,
      carAvailable:
        employee && employee.carAvailable ? employee.carAvailable : false,
      blackPantsuit:
        employee && employee.blackPantsuit ? employee.blackPantsuit : false,
      blackSkirtBlazer:
        employee && employee.blackSkirtBlazer
          ? employee.blackSkirtBlazer
          : false,
      preferedJobs:
        employee && employee.preferedJobs
          ? employee.preferedJobs.map((preferedJob) => {
              return { label: preferedJob, value: preferedJob };
            })
          : [],
      previousJobs:
        employee && employee.previousJobs
          ? employee.previousJobs.map((previousJob) => {
              return { label: previousJob, value: previousJob };
            })
          : [],
      jobExperience:
        employee && employee.jobExperience ? employee.jobExperience : "",
      preferedJobRegion:
        employee && employee.preferedJobRegion
          ? employee.preferedJobRegion.map((preferedJRegion) => {
              return { label: preferedJRegion, value: preferedJRegion };
            })
          : [],
      ssn: employee ? employee.ssn : "",
      issuedName: employee ? employee.issuedName : "",
      nameOfInstitute: employee ? employee.nameOfInstitute : "",
      iban: employee ? employee.iban : "",
      bic: employee ? employee.bic : "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is Required"),
      lastName: Yup.string().required("Last Name is Required"),
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is Required"),
      phone: Yup.number()
        .typeError("That doesn't look like a phone number")
        .positive("A phone number can't start with a minus")
        .integer("A phone number can't include a decimal point")
        .required("A phone number is required")
        .test(
          "maxDigits",
          "Phone number must have atleast 9 digits",
          (number) => String(number).length >= 9
        ),
      //images
      gender: Yup.string().required("Gender is Required"),
      motherTongue: Yup.string().required("Mother toungue is Required"),

      dateOfBirth: Yup.number()
        .typeError("Field must be a number")
        .required("Date of Birth is Required"),
      height: Yup.number()
        .typeError("Field must be a number")
        .required("Height is Required"),
      weight: Yup.number()
        .typeError("Field must be a number")
        .required("Weight is Required"),
      nationality: Yup.string().required("Nationality is Required"),
      hairColor: Yup.string().required("Hair Color is Required"),
      shoeSize: Yup.number()
        .typeError("Field must be a number")
        .required("Show Size is Required"),
      size: Yup.string().required("Size is Required"),
      country: Yup.string().required("Country is Required"),
      city: Yup.string().required("City is Required"),
      addressLineOne: Yup.string().required("Address is Required"),
      postalCode: Yup.string()
        .required("Postal code is Required")
        .matches(/^[0-9]/, "Postal Code can not be negative")
        .min(4, "Postal Code must be 4-5 digit")
        .max(5, "Postal Code must be 4-5 digit"),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      if (user.role === roles.employee) {
        const preferedJobValue = values.preferedJobs.map((prefJob) => {
          return prefJob.value;
        });
        const preferedJobregionsValue = values.preferedJobRegion.map(
          (prefJob) => {
            return prefJob.value;
          }
        );

        const prevJobValue = values.previousJobs.map((prevJob) => {
          return prevJob.value;
        });

        let newValues = {
          ...values,
          images: values.images.map((file) => {
            if (
              typeof file === "string" &&
              file.includes(process.env.REACT_APP_GCS_BUCKET)
            ) {
              return getUnsignedURL(file);
            } else {
              return file;
            }
          }),
          weight: values.weight
            ? parseInt(values.weight.replace("kg", ""))
            : values,
          height: values.height
            ? parseInt(values.height.replace("cm", ""))
            : values,
          preferedJobs: preferedJobValue,
          preferedJobRegion: preferedJobregionsValue,
          previousJobs: prevJobValue,
        };
        let formData = new FormData();

        Object.entries(newValues).map(([key, value]) => {
          if (key === "images") {
            for (const image of value) {
              formData.append(key, image);
            }
          } else {
            formData.append(key, value);
          }
          return true;
        });
        setClassicModal({ ...classicModal, isVisible: false });

        dispatch(
          updateEmployeeUser({
            userID: user._id,
            employeeID: user.employee._id,
            payload: {
              ...values,
            },
            formData,
            callback: () => {
              setLoading(false);
            },
          })
        );
      }
    },
  });

  const removeImage = (imgId) => {
    const newImages = formik.values.images.filter((i, id) => imgId !== id);
    formik.setFieldValue("images", newImages);
    const newImg = userImg.filter((i, id) => imgId !== id);
    setUserImg(newImg);
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="form form-first-registration"
    >
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div className="employee-img-wrapper">
            <label>Staff Profile Images</label>
            {formik.values.images && formik.values.images.length ? (
              <>
                <GridContainer>
                  {imgLoading ? (
                    <CircularProgress />
                  ) : (
                    userImg.map((i, index) => (
                      <GridItem xs={3} md={3} lg={3} key={index}>
                        <div className="img-in">
                          <img
                            key={index}
                            src={
                              userImg
                                ? i?.data
                                  ? i.data.image
                                  : URL.createObjectURL(i)
                                : ""
                            }
                            alt="Profile"
                            className="img-in"
                          />
                          <div
                            className="remove-img"
                            onClick={() => removeImage(index)}
                          >
                            <CloseIcon />
                          </div>
                        </div>
                      </GridItem>
                    ))
                  )}
                </GridContainer>
              </>
            ) : (
              <>
                <div>No Profile Images Found!</div>
              </>
            )}
            <div className="input-file-wrapper">
              <input
                type="file"
                name="file"
                onChange={async (e) => {
                  if (e.target.files[0] && e.target.files[0].size < 2097152) {
                    formik.setFieldValue("images", [
                      ...formik.values.images,
                      e.target.files[0],
                    ]);
                    setUserImg((prevState) => [
                      ...prevState,
                      e.target.files[0],
                    ]);
                    setImgLoading(false);
                  } else {
                    toast.error("Image size should not be greater than 2MB.");
                  }
                }}
              />
              <Button
                type="button"
                variant="contained"
                className="btn-add-img"
                color="primary"
              >
                Add Image
              </Button>
            </div>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="First Name"
            id="firstName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "firstName",
              value: formik.values.firstName,
              onChange: (e) => {
                if (e.target.value === "" || e.target.value?.length <= 30) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.firstName,
              errors: formik.errors.firstName,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Last Name"
            id="lastName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "lastName",
              value: formik.values.lastName,
              onChange: (e) => {
                if (e.target.value === "" || e.target.value?.length <= 50) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.lastName,
              errors: formik.errors.lastName,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Email adress"
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "email",
              name: "email",
              value: formik.values.email,
              onChange: (e) => {
                if (e.target.value === "" || e.target.value?.length <= 30) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.email,
              errors: formik.errors.email,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Phone Number"
            id="phone"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "phone",
              value: formik.values.phone,
              onChange: (e) => {
                if (e.target.value === "" || e.target.value?.length <= 20) {
                  formik.handleChange(e);
                }
              },
              touched: formik.touched.phone,
              errors: formik.errors.phone,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div className="date-of-birth-wrapper">
            <InputLabel
              className={classes.label}
              style={{
                textAlign: "left",
              }}
            >
              Date of Birth
            </InputLabel>
            <br />
            <FormControl fullWidth>
              <Datetime
                timeFormat={false}
                dateFormat="DD-MM-YYYY"
                initialValue={formik.values.dateOfBirth}
                inputProps={{ placeholder: "Date" }}
                closeOnSelect={true}
                isValidDate={disableFutureDt}
                onChange={(e) => {
                  var d = e._d;
                  const time = new Date(d).valueOf();
                  formik.setFieldValue("dateOfBirth", time);
                }}
              />
            </FormControl>
            {formik.touched.dateOfBirth && formik.errors.dateOfBirth && (
              <div className="error-message">{formik.errors.dateOfBirth}</div>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Weight (in kg)"
            id="weight"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "weight",
              value: formik.values.weight,
              onChange: formik.handleChange,
              touched: formik.touched.weight,
              errors: formik.errors.weight,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Height (in cm)"
            id="height"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "height",
              value: formik.values.height,
              onChange: formik.handleChange,
              touched: formik.touched.height,
              errors: formik.errors.height,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CheckboxWrapper
            name="gender"
            selectedEnabled={formik.values.gender}
            handleChangeEnabled={formik.handleChange}
            options={[
              {
                value: "male",
                label: "Male",
              },
              {
                value: "female",
                label: "Female",
              },
              {
                value: "other",
                label: "Other",
              },
            ]}
          />
          {formik.touched.gender && formik.errors.gender && (
            <div className="error-message">{formik.errors.gender}</div>
          )}
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Hair Color
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.hairColor}
              onChange={(e) => {
                formik.setFieldValue("hairColor", e.target.value);
              }}
              inputProps={{
                name: "hairColor",
                id: "hairColor",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Hair Color
              </MenuItem>
              {hairColorOptions.map((hairColor, hairId) => (
                <MenuItem
                  key={hairColor + hairId}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={hairColor.value}
                >
                  {hairColor.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.hairColor && formik.errors.hairColor && (
              <div className="error-message">{formik.errors.hairColor}</div>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Size
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.size}
              onChange={(e) => {
                formik.setFieldValue("size", e.target.value);
              }}
              inputProps={{
                name: "size",
                id: "size",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Size
              </MenuItem>
              {sizeOptions.map((size, hairId) => (
                <MenuItem
                  key={size + hairId}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={size.value}
                >
                  {size.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.size && formik.errors.size && (
              <div className="error-message">{formik.errors.size}</div>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Shoe Size
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.shoeSize}
              onChange={(e) => {
                formik.setFieldValue("shoeSize", e.target.value);
              }}
              inputProps={{
                name: "shoeSize",
                id: "shoeSize",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Shoe Size
              </MenuItem>
              {shoeSizeOptions.map((shoeSize, hairId) => (
                <MenuItem
                  key={shoeSize + hairId}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={shoeSize.value}
                >
                  {shoeSize.label}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.shoeSize && formik.errors.shoeSize && (
              <div className="error-message">{formik.errors.shoeSize}</div>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div className="d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.piercings}
                  onClick={formik.handleChange}
                  name="piercings"
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Piercings"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div className="d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.visibleTattoos}
                  onClick={formik.handleChange}
                  name="visibleTattoos"
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Visible Tattoos"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div className="d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.driversLicense}
                  onClick={formik.handleChange}
                  name="driversLicense"
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Drivers License"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div className="d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.carAvailable}
                  onClick={formik.handleChange}
                  name="carAvailable"
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Car Available"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div className="d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.blackPantsuit}
                  onClick={formik.handleChange}
                  name="blackPantsuit"
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Private Black Pantsuit"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <div className="d-flex">
            <FormControlLabel
              control={
                <Checkbox
                  checked={formik.values.blackSkirtBlazer}
                  onClick={formik.handleChange}
                  name="blackSkirtBlazer"
                  checkedIcon={<Check className={classes.checkedIcon} />}
                  icon={<Check className={classes.uncheckedIcon} />}
                  classes={{
                    checked: classes.checked,
                    root: classes.checkRoot,
                  }}
                />
              }
              classes={{
                label: classes.label,
                root: classes.labelRoot,
              }}
              label="Private Black Skirt/Blazer"
            />
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Nationality
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.nationality}
              onChange={(e) => {
                formik.setFieldValue("nationality", e.target.value);
              }}
              inputProps={{
                name: "nationality",
                id: "nationality",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Nationality
              </MenuItem>
              {countries.map((country, cid) => (
                <MenuItem
                  key={country + cid}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={country.code}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.hairColor && formik.errors.hairColor && (
              <div className="error-message">{formik.errors.hairColor}</div>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Mother Toungue
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.motherTongue}
              onChange={(e) => {
                formik.setFieldValue("motherTongue", e.target.value);
              }}
              inputProps={{
                name: "motherTongue",
                id: "motherTongue",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Langauge
              </MenuItem>
              {languages.map((langauge, lId) => (
                <MenuItem
                  key={langauge + lId}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={langauge.code}
                >
                  {langauge.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.motherTongue && formik.errors.motherTongue && (
              <div className="error-message">{formik.errors.motherTongue}</div>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel
              htmlFor="multiple-select"
              className={classes.selectLabel}
            >
              Choose Foreign Languages
            </InputLabel>
            <Select
              multiple
              value={formik.values.foreignLanguages}
              onChange={(e) => {
                formik.setFieldValue("foreignLanguages", e.target.value);
              }}
              MenuProps={{ className: classes.selectMenu }}
              classes={{ select: classes.select }}
              inputProps={{
                name: "foreignLanguages",
                id: "foreign-languages",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Languages
              </MenuItem>
              {languages.map((langauge, lId) => (
                <MenuItem
                  key={langauge + lId}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={langauge.code}
                >
                  {langauge.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.foreignLanguages &&
              formik.errors.foreignLanguages && (
                <div className="error-message">
                  {formik.errors.foreignLanguages}
                </div>
              )}
          </FormControl>
        </GridItem>

        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.selectFormControl}>
            <InputLabel htmlFor="simple-select" className={classes.selectLabel}>
              Country
            </InputLabel>
            <Select
              MenuProps={{
                className: classes.selectMenu,
              }}
              classes={{
                select: classes.select,
              }}
              value={formik.values.country}
              onChange={(e) => {
                formik.setFieldValue("country", e.target.value);
              }}
              inputProps={{
                name: "country",
                id: "country",
              }}
            >
              <MenuItem
                disabled
                classes={{
                  root: classes.selectMenuItem,
                }}
              >
                Choose Country
              </MenuItem>
              {countries.map((country, cid) => (
                <MenuItem
                  key={country + cid}
                  classes={{
                    root: classes.selectMenuItem,
                    selected: classes.selectMenuItemSelected,
                  }}
                  value={country.code}
                >
                  {country.name}
                </MenuItem>
              ))}
            </Select>
            {formik.touched.country && formik.errors.country && (
              <div className="error-message">{formik.errors.country}</div>
            )}
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="City"
            id="city"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "city",
              value: formik.values.city,
              onChange: formik.handleChange,
              touched: formik.touched.city,
              errors: formik.errors.city,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Postal Code"
            id="postalCode"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "postalCode",
              value: formik.values.postalCode,
              onChange: formik.handleChange,
              touched: formik.touched.postalCode,
              errors: formik.errors.postalCode,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Address Line One"
            id="addressLineOne"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "addressLineOne",
              value: formik.values.addressLineOne,
              onChange: formik.handleChange,
              touched: formik.touched.addressLineOne,
              errors: formik.errors.addressLineOne,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Address Line Two"
            id="addressLineTwo"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "addressLineTwo",
              value: formik.values.addressLineTwo,
              onChange: formik.handleChange,
              touched: formik.touched.addressLineTwo,
              errors: formik.errors.addressLineTwo,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="SSN"
            id="ssn"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "ssn",
              value: formik.values.ssn,
              onChange: (e) => {
                const { value } = e.target;
                const upperValue = value
                  .toUpperCase()
                  .replace(/[^A-Z0-9]/g, "");
                if (upperValue.length <= 16) {
                  formik.handleChange({
                    target: { name: "ssn", value: upperValue },
                  });
                }
              },
              touched: formik.touched.ssn,
              errors: formik.errors.ssn,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Issued Name"
            id="issuedName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "issuedName",
              value: formik.values.issuedName,
              onChange: formik.handleChange,
              touched: formik.touched.issuedName,
              errors: formik.errors.issuedName,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Name of Institute"
            id="nameOfInstitute"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "nameOfInstitute",
              value: formik.values.nameOfInstitute,
              onChange: formik.handleChange,
              touched: formik.touched.nameOfInstitute,
              errors: formik.errors.nameOfInstitute,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Iban"
            id="iban"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "iban",
              value: formik.values.iban,
              onChange: formik.handleChange,
              touched: formik.touched.iban,
              errors: formik.errors.iban,
              onBlur: formik.handleBlur,
              maxLength: 27,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <CustomInput
            labelText="Bic"
            id="bic"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "bic",
              value: formik.values.bic,
              onChange: formik.handleChange,
              touched: formik.touched.bic,
              errors: formik.errors.bic,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <CustomInput
            labelText="Job Experience"
            id="jobExperience"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              type: "text",
              name: "jobExperience",
              value: formik.values.jobExperience,
              onChange: formik.handleChange,
              touched: formik.touched.jobExperience,
              errors: formik.errors.jobExperience,
              onBlur: formik.handleBlur,
            }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className="prefered-jobs-wrapper">
            <label>Prefered Job Regions</label>
            <JobSelect
              name="preferedJobregions"
              options={jobRegions}
              isMulti
              defaultValue={
                employee &&
                employee.preferedJobRegion &&
                employee.preferedJobRegion.map((preferedJobReg) => {
                  return { label: preferedJobReg, value: preferedJobReg };
                })
              }
              onChange={(regularTags) => {
                formik.setFieldValue("preferedJobRegion", regularTags);
              }}
              className="basic-multi-select preferedJobsSelect"
              classNamePrefix="select"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={style}
            />

            {formik.touched.preferedJobRegion &&
              formik.errors.preferedJobRegion && (
                <div className="error-message">
                  {formik.errors.preferedJobRegion}
                </div>
              )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className="prefered-jobs-wrapper">
            <label>Prefered Jobs</label>
            <JobSelect
              name="preferedJobs"
              options={jobPositionsList}
              isMulti
              defaultValue={
                employee &&
                employee.preferedJobs &&
                employee.preferedJobs.map((preferedJob) => {
                  return { label: preferedJob, value: preferedJob };
                })
              }
              onChange={(regularTags) => {
                formik.setFieldValue("preferedJobs", regularTags);
              }}
              className="basic-multi-select preferedJobsSelect"
              classNamePrefix="select"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={style}
            />
            {formik.touched.preferedJobs && formik.errors.preferedJobs && (
              <div className="error-message">{formik.errors.preferedJobs}</div>
            )}
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className="previous-jobs-wrapper">
            <label>Previous Jobs</label>
            <JobSelect
              name="previousJobs"
              options={jobPositionsList}
              isMulti
              defaultValue={
                employee &&
                employee.previousJobs &&
                employee.previousJobs.map((prevJob) => {
                  return { label: prevJob, value: prevJob };
                })
              }
              onChange={(regularTags) => {
                formik.setFieldValue("previousJobs", regularTags);
              }}
              className="basic-multi-select previousJobsSelect"
              classNamePrefix="select"
              components={{
                DropdownIndicator: () => null,
                IndicatorSeparator: () => null,
              }}
              styles={style}
            />
            {/* <TagsInput
              value={formik.values.previousJobs}
              onChange={(regularTags) =>
                formik.setFieldValue("previousJobs", regularTags)
              }
              tagProps={{ className: "react-tagsinput-tag info" }}
            /> */}
            {formik.touched.previousJobs && formik.errors.previousJobs && (
              <div className="error-message">{formik.errors.previousJobs}</div>
            )}
          </div>
        </GridItem>

        <GridItem xs={12} sm={12} md={12}>
          <div className="d-flex justify-content-end btn-wrapper bw-update-employer">
            <Button
              onClick={() =>
                setClassicModal({ ...classicModal, isVisible: false })
              }
              variant="outlined"
            >
              Cancel
            </Button>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </div>
        </GridItem>
      </GridContainer>
    </form>
  );
};

UpdateEmployee.propTypes = {
  user: PropTypes.any,
  classicModal: PropTypes.any,
  setClassicModal: PropTypes.any,
  setLoading: PropTypes.any,
};

export default UpdateEmployee;
