import { orderConstants } from "./types";
import { toast } from "react-toastify";
import api from "../variables/api";

export const getOrders = () => {
  const request = () => {
    return { type: orderConstants.GET_ORDER_REQUEST };
  };
  const success = (orders) => {
    return { type: orderConstants.GET_ORDER_SUCCESS, orders };
  };
  const failure = (error) => {
    return { type: orderConstants.GET_ORDER_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      const orders = await api.get("/orders");
      dispatch(success(orders.data.data.orders));
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};

export const deleteOrder = ({ orderID }) => {
  const request = () => {
    return { type: orderConstants.DELETE_ORDER_REQUEST };
  };
  const success = () => {
    return { type: orderConstants.DELETE_ORDER_SUCCESS };
  };
  const failure = (error) => {
    return { type: orderConstants.DELETE_ORDER_FAILURE, error };
  };

  return async (dispatch) => {
    dispatch(request());

    try {
      await api.delete(`/orders/deleteOrder/${orderID}`);
      dispatch(success());
    } catch (error) {
      if (
        error &&
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        dispatch(failure(`${error.response.data.message}`));
        toast.error(`${error.response.data.message}`);
      } else {
        dispatch(failure("Something went wrong"));
        toast.error("Something went wrong");
      }
    }
  };
};
