import { bookingConstants } from "../actions/types";

const initialState = { loading: false, data: [], error: "" };

const bookingReducer = (state = initialState, action) => {
  switch (action.type) {
    case bookingConstants.GET_BOOKING_REQUEST:
      return {
        ...state,
        loading: true,
        error: "",
      };
    case bookingConstants.GET_BOOKING_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.bookings,
        error: "",
      };
    case bookingConstants.GET_BOOKING_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return { ...state };
  }
};

export default bookingReducer;
